

.scrollable-container-right-signup{
    scrollbar-width: 5px !important;
    padding-right: 0.2vw;
}
.scrollable-container-right-signup::-webkit-scrollbar{
    display: flex;
    width: 5px !important;
    
}
.scrollable-container-right-signup::-webkit-scrollbar-track{
    background-color: transparent;
    width: 5px !important;
}
.scrollable-container-right-signup::-webkit-scrollbar-thumb{
    background-color: #A91674;
    width: 5px !important;
    border-radius: 100px;
}


.container-left {
    border-right: 0.078vw solid #A91674
}





.auth-gradient-left {
    position: absolute;
    top: 0px;
    z-index: -1;
    left: 0px;
    height: 100vh;
    width: 48.438vw;
}

.auth-gradient-left img {
    height: 90%;
    width: 100%;
    
}

.auth-gradient-right {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: -1;
    height: 100vh;
    width:25.969vw;
}

.auth-gradient-right img {
    height: 100%;
    
    width: 100%;
}