.p-1 {
    /* padding: 48px; */
    padding: 3.750vw;

}

.p-2 {
    /* padding: 24px; */
    padding: 1.875vw;
}
.p-3 {
    padding: 0.938vw;/*12px*/
}
.pt-1 {
    /* padding-top: 48px; */
    padding-top: 3.750vw;

}

.pt-2 {
    /* padding-top: 24px; */
    padding-top: 1.875vw;
}
.pt-3 {
    padding-top: 0.938vw;/*12px*/
}
.pb-1 {
    /* padding-bottom: 48px; */
    padding-bottom: 3.750vw;

}

.pb-2 {
    /* padding-bottom: 24px; */
    padding-bottom: 1.875vw;
}
.pb-3 {
    padding-bottom: 0.938vw;/*12px*/
}
.pl-1 {
    /* padding-left: 48px; */
    padding-left: 3.750vw;

}

.pl-2 {
    /* padding-left: 24px; */
    padding-left: 1.875vw;
}
.pl-3 {
    padding-left: 0.938vw;/*12px*/
}
.pr-1 {
    /* padding-right: 48px; */
    padding-right: 3.750vw;

}

.pr-2 {
    /* padding-right: 24px; */
    padding-right: 1.875vw;
}
.pr-3 {
    padding-right: 0.938vw;/*12px*/
}




.m-1 {
    /* margin: 48px; */
    margin: 3.750vw;

}

.m-2 {
    /* margin: 24px; */
    margin: 1.875vw;
}
.m-3 {
    margin: 0.938vw;/*12px*/
}
.mt-1 {
    /* margin-top: 48px; */
    margin-top: 3.750vw;

}

.mt-2 {
    /* margin-top: 24px; */
    margin-top: 1.875vw;
}
.mt-3 {
    margin-top: 0.938vw;/*12px*/
}
.mb-1 {
    /* margin-bottom: 48px; */
    margin-bottom: 3.750vw;

}

.mb-2 {
    /* margin-bottom: 24px; */
    margin-bottom: 1.875vw;
}
.mb-3 {
    margin-bottom: 0.938vw;/*12px*/
}
.ml-1 {
    /* margin-left: 48px; */
    margin-left: 3.750vw;

}

.ml-2 {
    /* margin-left: 24px; */
    margin-left: 1.875vw;
}
.ml-3 {
    margin-left: 0.938vw;/*12px*/
}
.mr-1 {
    /* margin-right: 48px; */
    margin-right: 3.750vw;

}

.mr-2 {
    /* margin-right: 24px; */
    margin-right: 1.875vw;
}
.mr-3 {
    margin-right: 0.938vw;/*12px*/
}


.radius-1{
    border-radius: 2.500vw;/*32px/
}
.radius-2{
    border-radius: 0.938vw;/*12px*/
}
.radius-3{
    border-radius: 0.625vw/*8px*/;
}
.radius-4{
    border-radius: 0.313vw/*4px*/;
}


.radius-b-1{
    border-radius: 0px 0px 2.500vw /*32px/2.500vw;/*32px/
}
.radius-b-2{
    border-radius: 0px 0px 0.938vw 0.938vw;/*12px*/
}
.radius-b-3{
    border-radius: 0px 0px 0.625vw/*8px*/ 0.625vw/*8px*/;
}
.radius-b-4{
    border-radius: 0px 0px 0.313vw/*4px*/ 0.313vw/*4px*/;
}

.radius-t-1{
    border-radius:  2.500vw /*32px/2.500vw /*32px/0px 0px;
}
.radius-t-2{
    border-radius:  0.938vw 0.938vw 0px 0px;/*12px*/
}
.radius-t-3{
    border-radius:  0.625vw/*8px*/ 0.625vw/*8px*/ 0px 0px;
}

.radius-curved{
    border-radius: 100px;
}

.radius-rounded{
    border-radius: 100%;
}

.p-btn{
    padding: 0.391vw 1.563vw;
}
.css-g3i2vt-MuiTableCell-root{
    border-bottom: none !important;
}